import React from 'react'
import { getLogos } from '../services/portfolio.service'

export default function Hero() {
  const logos = getLogos()
  return (
    <section id='home' className='hero-section'>
      <div className='hero-container'>
        <div className='content'>
          <div className='hero-main'>
            <div className='hero-info'>
              <h1>Front-End Web Developer</h1>
              <p>
                Hi, I'm Guy Shilon, a passionate creative Front-End Developer
                based in Ness Ziona, Israel.
              </p>
              <span className='socials'>
                <a
                  href='https://www.linkedin.com/in/guyshilon/'
                  target='_blank'
                >
                  <i className='fa-brands fa-linkedin'></i>
                </a>
                <a href='https://github.com/Spaiz4564' target='_blank'>
                  <i className='fa-brands fa-github'></i>
                </a>
              </span>
              <img
                src='https://ik.imagekit.io/tfcgjzp4c/Portfolio_imgs/hand.png?updatedAt=1685124347083'
                alt=''
              />
            </div>
            <div className='hero-img'></div>
          </div>
          <div className='skills'>
            <p>Tech Stack</p>
            <div className='logos'>
              <ul>
                {logos.map(logo => (
                  <li key={logo.name}>
                    <img src={logo.imgUrl} alt='logo-img' title={logo.name} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
