import React, { useEffect, useState } from 'react'
import { getSections } from '../services/portfolio.service'

export default function SideMenu({ isOpen, menuHandler }) {
  const [showList, setShowList] = useState(false)
  const sections = getSections()

  const ulStyle = { display: `${isOpen ? 'block' : 'none'}` }

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setShowList(true), 250)
    } else {
      setShowList(false)
    }
  }, [isOpen])

  const sectionsList = sections.map(section => (
    <li onClick={menuHandler} key={section}>
      <a href={`#${section.toLowerCase()}`}>{section}</a>
    </li>
  ))

  return (
    <div className='side-menu-container'>
      <div className={`side-menu ${isOpen ? 'add-height' : ''}`}>
        <ul style={ulStyle} className={`${showList ? 'show-list' : ''}`}>
          {sectionsList}
        </ul>
      </div>
    </div>
  )
}
