import React from 'react'
import ProjectPreview from './ProjectPreview'
import { getProjects } from '../services/portfolio.service'

export default function Projects() {
  const projects = getProjects()
  return (
    <section id='projects' className='projects-section'>
      <div className='projects-container'>
        <div className='projects-content'>
          <h4 className='blue-h4'>Portfolio</h4>
          <h3 className='grey-h3'>
            Each project is a unique piece of development
          </h3>
          <div className='projects-grid'>
            {projects.map((project,i) => (
              <ProjectPreview key={project._id} index={i} project={project} />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
