import React, { useRef, useState, useEffect } from 'react'
import { getSections } from '../services/portfolio.service'
import SideLinks from './SideLinks'
import SideMenu from './SideMenu'

export default function Nav() {
  const [menuIcon, setMenuIcon] = useState(false)
  const sections = getSections()
  const navRef = useRef(null)

  const sectionsList = sections.map(section => (
    <li key={section}>
      <a href={`#${section.toLowerCase()}`}>{section}</a>
    </li>
  ))

  const sideMenuHandler = () => {
    setMenuIcon(prevState => !prevState)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  function handleClickOutside(event) {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setMenuIcon(false)
    }
  }

  return (
    <div className='nav-container'>
      <SideLinks />
      <nav ref={navRef}>
        <a href='#home'>
          <h3>Guy.dev</h3>
        </a>
        <div onClick={sideMenuHandler} className='mobile-icon'>
          {!menuIcon && (
            <i className='hamburger fa-solid fa-bars-staggered mobile-menu'></i>
          )}
          {menuIcon && <i className='cancel fa-solid fa-xmark'></i>}
        </div>
        <SideMenu menuHandler={sideMenuHandler} isOpen={menuIcon} />
        <ul>{sectionsList}</ul>
      </nav>
    </div>
  )
}
