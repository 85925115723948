import React from 'react'
import Nav from '../cmps/Nav'
import Hero from '../cmps/Hero'
import AboutMe from '../cmps/AboutMe'
import Projects from '../cmps/Projects'
import Contact from '../cmps/Contact'

export default function PortfolioIndex() {
  return (
    <>
      <Nav />
      <Hero />
      <AboutMe />
      <Projects />
      <Contact />
    </>
  )
}
