import React, { useState } from 'react'

export default function ProjectPreview({ project, index }) {
  const [movement, setMovement] = useState(0)
  const style = { transform: `translate(${0}%, ${movement}%)` }

  const appsusHeight = project._id === 'p3' ? 'appsus-height' : ''

  return (
    <div
      className={`project-preview ${
        index % 2 === 0 ? '' : 'reverse'
      } ${appsusHeight} `}
    >
      <div className='pro-img'>
        <a href={project.demo} target='_blank' rel='noreferrer'>
          <img
            style={style}
            onMouseOver={() => setMovement(project.translate)}
            onMouseOut={() => setMovement(0)}
            src={project.imgUrl}
            alt=''
          />
        </a>
      </div>
      <div className='pro-text'>
        <h3 className='preview-h3 grey-h3'>{project.name}</h3>
        <p>{project.text}</p>
        <div className='stack'>
          {project.stack.map(skill => (
            <p key={skill}>{skill}</p>
          ))}
        </div>
        <div className='links'>
          <a href={project.code} target='_blank' rel='noreferrer'>
            Code <i className='fa-brands fa-github'></i>
          </a>
          <a href={project.demo} target='_blank' rel='noreferrer'>
            Live Demo <i className='fa-solid fa-arrow-up-right-from-square'></i>
          </a>
        </div>
      </div>
    </div>
  )
}
