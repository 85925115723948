import React, { useEffect, useState } from 'react'

export default function SideLinks() {
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset)
    // clean up code
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  const scrollHandler = Math.floor(offset / 21.5) + 1 * -36

  return (
    <div
      style={{ bottom: `${scrollHandler <= 0 ? scrollHandler : 0}%` }}
      className='side-links '
    >
      <ul>
        <li>
          <a href='https://github.com/Spaiz4564' target='_blank'>
            <i className='fa-brands fa-github'></i>
          </a>
        </li>
        <li>
          <a href='https://www.linkedin.com/in/guyshilon/' target='_blank'>
            <i className='fa-brands fa-linkedin'></i>
          </a>
        </li>
        <li>
          <a href='mailto:guyshilon30@gmail.com' target='_blank'>
            <i className='fa-solid fa-envelope'></i>
          </a>
        </li>
        <li>
          <a href='https://wa.me/972548073664' target='_blank'>
            <i className='fa-brands fa-whatsapp'></i>
          </a>
        </li>
      </ul>
      <div className='side-line'></div>
    </div>
  )
}
