import React from 'react'

export default function Contact() {
  return (
    <section id='contact'>
      <div className='contact-container'>
        <div className='contact-content'>
          <div className='contact-title'>
            <h4 className='blue-h4'>Contact</h4>
            <h3 className='grey-h3'>Don't be shy! Hit me up!</h3>
          </div>
          <div className='contact-icons'>
            <div className='contact-icon-box flex align-center'>
              <span className='phone'>
                <i className='fa-solid fa-mobile-screen-button'></i>
              </span>
              <div className='contact-info'>
                <h3>Phone</h3>
                <p>+972 548073664</p>
              </div>
            </div>
            <div className='contact-icon-box flex align-center'>
              <span>
                <i className='fa-solid fa-envelope'></i>
              </span>
              <div className='contact-info'>
                <h3>Mail</h3>
                <a href='mailto:guyshilon30@gmail.com'>guyshilon30@gmail.com</a>
              </div>
            </div>
            <div className='contact-icon-box flex align-center'>
              <span>
                <i className='fa-solid fa-map-location-dot'></i>
              </span>
              <div className='contact-info'>
                <h3>Location</h3>
                <p>Ness ziona, Israel</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
