import React from 'react'

export default function AboutMe() {
  return (
    <section id='about' className='about-section'>
      <div className='about-container'>
        <div className='about-content'>
          <div className='about-img'>
            <img
              className='work-emoji'
              src='https://ik.imagekit.io/tfcgjzp4c/Portfolio_imgs/User_Icon.png?updatedAt=1685120332561'
              alt=''
            />
            <img
              className='laptop-img'
              src='https://ik.imagekit.io/tfcgjzp4c/Portfolio_imgs/LAPTOP.webp?updatedAt=1685120333320'
              alt=''
            />
            <span>
              <img
                src='https://ik.imagekit.io/tfcgjzp4c/Portfolio_imgs/circle.svg?updatedAt=1685120332704'
                alt=''
              />
            </span>
          </div>
          <div className='about-info'>
            <h4 className='blue-h4'>About me</h4>
            <h3 className='grey-h3'>
              A dedicated Front-end Developer based in Ness Ziona, Israel
            </h3>
            <p>
              Front-End Developer with a comprehensive skill set acquired
              through an intensive coding bootcamp, I am proficient in HTML,
              CSS, JavaScript, React, Vue, and SCSS. With expertise in creating
              responsive websites that deliver seamless user experiences, I
              excel in designing and maintaining dynamic interfaces. My clean
              coding practices, coupled with the use of cutting-edge development
              tools, enable me to craft engaging web applications. Additionally,
              I am a team player who thrives in collaborating with
              cross-functional teams to produce outstanding web applications.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
