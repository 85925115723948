export { getSections, getLogos, getProjects }

function getSections() {
  return ['Home', 'About', 'Projects', 'Contact']
}

function getLogos() {
  return [
    {
      name: 'HTML',
      imgUrl:
        'https://ik.imagekit.io/tfcgjzp4c/Portfolio_imgs/HTML.svg?updatedAt=1685120332687',
    },
    {
      name: 'CSS',
      imgUrl:
        'https://ik.imagekit.io/tfcgjzp4c/Portfolio_imgs/CSS.svg?updatedAt=1685120332670',
    },
    {
      name: 'JS',
      imgUrl:
        'https://ik.imagekit.io/tfcgjzp4c/Portfolio_imgs/JS.svg?updatedAt=1685120332645',
    },
    {
      name: 'REACT',
      imgUrl:
        'https://ik.imagekit.io/tfcgjzp4c/Portfolio_imgs/REACT.svg?updatedAt=1685120332652',
    },
    {
      name: 'VUE',
      imgUrl:
        'https://ik.imagekit.io/tfcgjzp4c/Portfolio_imgs/VUE.svg?updatedAt=1685189485762',
    },
    {
      name: 'NODEJS',
      imgUrl:
        'https://ik.imagekit.io/tfcgjzp4c/Portfolio_imgs/NODE.svg?updatedAt=1685189670644',
    },
    {
      name: 'SCSS',
      imgUrl:
        'https://ik.imagekit.io/tfcgjzp4c/Portfolio_imgs/SCSS.svg?updatedAt=1685120332937',
    },
  ]
}

function getProjects() {
  return [
    {
      _id: 'p1',
      name: 'NICERR',
      translate: -52,
      text: `a responsive marketplace website inspired by Fiverr, built with the latest technologies. Experience a user-friendly interface and diverse services.`,
      stack: ['Vue', 'SCSS', 'NodeJS', 'MongoDB', 'Sockets'],
      imgUrl:
        'https://ik.imagekit.io/tfcgjzp4c/Portfolio_imgs/Nicerr.png?updatedAt=1685279068985',
      demo: 'https://nicerr.onrender.com/#/',
      code: 'https://github.com/Spaiz4564/Nicerr---Frontend',
    },
    {
      _id: 'p2',
      name: 'YUNG DENTAL',
      translate: -81,
      text: `a custom-designed website for a dental laboratory. With its sleek and responsive design, this website beautifully highlights the laboratory's expertise and advanced services.`,
      stack: ['React', 'SCSS'],
      imgUrl:
        'https://ik.imagekit.io/tfcgjzp4c/Portfolio_imgs/screencapture-spaiz4564-github-io-Yung-Dental-2023-05-28-15_48_57.png?updatedAt=1685278220459',
      demo: 'https://spaiz4564.github.io/Yung-Dental/#/',
      code: 'https://github.com/Spaiz4564/Yung-Dental',
    },
    {
      _id: 'p3',
      name: 'APPSUS',
      translate: -17,
      text: `an app inspired by Gmail and Google Keep, offering seamless integration of both platforms. Effortlessly manage emails and organize notes in a single, user-friendly interface.`,

      stack: ['Vue', 'CSS3'],
      imgUrl:
        'https://ik.imagekit.io/tfcgjzp4c/Portfolio_imgs/Appsus.png?updatedAt=1685280978358',
      demo: 'https://spaiz4564.github.io/Appsus/#/',
      code: 'https://github.com/Spaiz4564/Appsus',
    },
  ]
}
